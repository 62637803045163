/** @jsx jsx */

//lib
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

//components
import { Container, NoSearchResults } from '@sprinklr/shared-lib';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

const NoLocaleContentTemplate: React.FC<{
  padding: ContainerPadding;
  isBlogPage?: boolean;
}> = ({ padding }) => {
  const [pt, pb] = useContainerPadding(padding);
  const { t } = useI18next();
  const [englishPath, setEnglishPath] = useState('');

  useEffect(() => {
    if (isWindowDefined()) {
      const localeSubPath = window.location.pathname.replace(
        /^(\/fr|\/gb|\/es|\/pt-br|\/de|\/it|\/ko|\/kr|\/jp|\/sg|\/au)/,
        '',
      );
      setEnglishPath(`${localeSubPath}${window.location.search}`);
    }
  }, []);

  return (
    <Container containerSx={{ pt, pb }}>
      <NoSearchResults
        searchHeadlineCopy={t(
          'noLocalizedContentHeadline',
          'We currently don’t have any of this content available in your language.',
        )}
        suggestionKey={'noLocalizedContentSuggestion'}
        suggestionLink={englishPath}
        suggestionLinkTextKey={'noLocalizedContentSuggestionLinkText'}
        suggestionLinksToEnglish={true}
      />
    </Container>
  );
};

export default NoLocaleContentTemplate;
