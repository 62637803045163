/** @jsx jsx */
import { useMemo, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ExploreLayout from '@sprinklr/website/exploreSrc/components/ExploreLayout';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { jsx } from 'theme-ui';
import { CardFullDisplay } from '@sprinklr/shared-lib';
import { filterDataByLocale } from '@sprinklr/shared-lib/utils';
import {
  productFilterOptions,
  eventsTypeFilterOptions,
  productLookup,
} from '../components/filters';
import NoLocaleContentTemplate from '../../../../src/templates/customTemplate/noLocaleContent/NoLocaleContent';
import { transformSplashEventsToResourceCards } from '../../../utils/transformSplashEventsToResourceCards';
import { getPostPageSlug } from '@sprinklr/shared-lib/utils/getPostPageSlugs';
export const eventsIndexPageQuery = graphql`
  query (
    $language: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $pageName: String! = "EVENTS"
  ) {
    ...HeaderFooterSocialQuery
    ...MetaDataByPageName
    ...EventsIndexQuery
    ...ExploreSiteMetadataQuery
    ...LocalesQuery
  }
`;

const EventsIndexPage = ({ data, pageContext }) => {
  const {
    allContentfulExploreResourcesCard,
    allContentfulEvent,
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    allContentfulMetadata,
    site,
  } = data;

  const { nodeLocale, splashEvents } = pageContext;

  const metadata = allContentfulMetadata?.nodes[0];
  const events = allContentfulEvent.nodes;
  const localizedEventsArray = filterDataByLocale(
    events,
    nodeLocale,
    'allLocalesToShowCard',
  ).map(event => {
    const fullSlug = getPostPageSlug(event.slug, 'events', nodeLocale);

    return { ...event, slug: fullSlug };
  });

  const exploreResourceCardEvents =
    allContentfulExploreResourcesCard?.nodes.map(node => ({ ...node }));
  const localizedResourceEventsArray = filterDataByLocale(
    exploreResourceCardEvents,
    nodeLocale,
    'translations',
  );

  // SPLASH events are only added for en-US locale
  const transformedSplashEventsArray =
    nodeLocale === 'en-US'
      ? transformSplashEventsToResourceCards(splashEvents)
      : [];

  const localizedEvents = useMemo(
    () =>
      localizedEventsArray
        .concat(localizedResourceEventsArray)
        .concat(transformedSplashEventsArray)
        .map(el => {
          const pastOrFuture =
            new Date(el.date) < new Date() ? 'onDemand' : 'upcoming';
          return {
            ...el,
            pastOrFuture: pastOrFuture,
            isDateDisplayed: true,
          };
        }),
    [],
  );

  const upcomingEvents = localizedEvents.filter(
    event => event.pastOrFuture === 'upcoming',
  );

  const flagshipEvents = upcomingEvents
    .filter(event => event.isFlagshipEvent)
    .map(event => {
      return {
        ...event,
        competitorLabel: 'Flagship Event',
        isDateDisplayed: false,
      };
    });

  const sortedNonFlagshipEvents = upcomingEvents
    .filter(event => !event.isFlagshipEvent)
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());

  const upcomingSortedEvents = [...flagshipEvents, ...sortedNonFlagshipEvents];

  // Filter out events that have been unpublished
  const onDemandSortedEvents = localizedEvents
    .filter(
      event =>
        !event.shouldRemovePageAfterEvent && event.pastOrFuture === 'onDemand',
    )
    .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

  const eventsToDisplay = [...upcomingSortedEvents, ...onDemandSortedEvents];

  const [productFilter, setProductFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [filteredEvents, setFilteredEvents] = useState(eventsToDisplay);
  const { t } = useI18next();

  useEffect(() => {
    const productFilteredEvents =
      productFilter === 'all'
        ? eventsToDisplay
        : eventsToDisplay.filter(
            e =>
              e.relatedProducts &&
              e.relatedProducts.includes(productLookup[productFilter]),
          );
    const typeSortedEvents =
      typeFilter === 'all'
        ? productFilteredEvents
        : productFilteredEvents.filter(
            e => e.pastOrFuture && e.pastOrFuture === typeFilter,
          );

    setFilteredEvents(typeSortedEvents);
  }, [productFilter, typeFilter]);

  const filterDetails = [
    {
      customDropdownLabel: 'Product',
      options: productFilterOptions,
      value: productFilter,
      setValue: setProductFilter,
    },
    {
      customDropdownLabel: 'Type',
      options: eventsTypeFilterOptions,
      value: typeFilter,
      setValue: setTypeFilter,
    },
  ];

  return (
    <ExploreLayout
      context={pageContext}
      slug={'/events'}
      metadata={metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
    >
      <CardFullDisplay
        headline={t('Events')}
        subHeadline={t(
          'Register now to hear the latest business-critical insights from Sprinklr experts at upcoming and on-demand events.',
        )}
        ctaBeneathSubHeadline={{
          ctaText: t('Looking for on-demand Sprinklr Demos? Click here.'),
          ctaUrl: '/demo-days/',
        }}
        overallPageType={'event'}
        cards={filteredEvents}
        filterDetails={filterDetails}
        shouldHideNoResultsMessage={!localizedEvents?.length && true}
        nodeLocale={nodeLocale}
        cardfullDisplaySx={{ pt: ['40px', 0, 0, '40px'] }}
      />
      {!localizedEvents?.length && (
        <NoLocaleContentTemplate padding={{ top: 'NONE', bottom: 'LARGE' }} />
      )}
    </ExploreLayout>
  );
};

export default EventsIndexPage;
