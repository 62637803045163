export const productFilterOptions = [
  {
    label: `All Products`,
    value: 'all',
    disabled: false,
  },
  {
    label: 'Sprinklr Service',
    value: 'care',
  },
  {
    label: 'Sprinklr Insights',
    value: 'research',
  },
  {
    label: 'Sprinklr Marketing',
    value: 'marketing',
  },
  {
    label: 'Sprinklr Social',
    value: 'sales',
  },
  {
    label: 'Platform',
    value: 'platform',
  },
];

export const resourceTypeFilterOptions = [
  {
    label: `All Resources`,
    value: 'all',
  },
  {
    label: 'Reports',
    value: 'reports',
  },
  {
    label: 'e-Books',
    value: 'ebooks',
  },
];

export const eventsTypeFilterOptions = [
  {
    label: `All Events`,
    value: 'all',
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'On-demand',
    value: 'onDemand',
  },
];

export const productLookup = {
  care: 'Sprinklr Service',
  marketing: 'Sprinklr Marketing',
  research: 'Sprinklr Insights',
  platform: 'Platform',
  sales: 'Sprinklr Social',
};

export const resourceTypeLookup = {
  ebooks: 'eBook',
  reports: 'Report',
};
