import { plainTextFromHtml } from './plainTextFromHtml';
import {
  CustomQuestion,
  SplashEvent,
} from '@sprinklr/website/scripts/splashEvents/types';

const productMapping: Record<string, string> = {
  'Product Category – Sprinklr Marketing': 'Sprinklr Marketing',
  'Product Category – Sprinklr Service': 'Sprinklr Service',
  'Product Category – Sprinklr Insights': 'Sprinklr Insights',
  'Product Category – Sprinklr Social': 'Sprinklr Social',
  'Product Category – Platform': 'Platform',
};

const getRelatedProducts = (customAttributes: CustomQuestion[]): string[] => {
  const relatedProducts: string[] = [];

  customAttributes.forEach((question: CustomQuestion) => {
    if (
      question.type === 'hidden' &&
      productMapping[question.name as keyof typeof productMapping]
    ) {
      relatedProducts.push(productMapping[question.name]);
    }
  });

  return relatedProducts;
};

export const transformSplashEventsToResourceCards = (
  data: Array<SplashEvent>,
) => {
  return data.map(item => {
    const customAttributes = item?.event_setting?.custom_questions || [];
    const relatedProducts = getRelatedProducts(customAttributes);

    return {
      id: item.id,
      title: item.title,
      description: plainTextFromHtml(item.description_text),
      slug: item.fq_url,
      date: item.event_start,
      type: 'EVENT',
      previewImage: item.event_setting.event_cards.x1,
      translations: ['English (en-US)'],
      isSplashEvent: true,
      relatedProducts,
    };
  });
};
